import {
    Autocomplete,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    MenuItem,
    TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CountryFlag } from "../../components/CountryFlag";
import { INPUT_VALIDATION } from "../../constants";
import { LoadingButton } from "@mui/lab";
import { MODEL_PROPTYPES } from "../../models";
import PropTypes from "prop-types";
import { Stack } from "@mui/system";
import { iso31661 } from "iso-3166";
import { useKcRole } from "../../hooks/useKcRole";
import { useMemo } from "react";

SiteCreateForm.propTypes = {
    contract: PropTypes.shape(MODEL_PROPTYPES.Contract).isRequired,
    closeModal: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape(MODEL_PROPTYPES.User)).isRequired,
};

export function SiteCreateForm({ contract, closeModal, mutate, users }) {
    const { isBasicRole } = useKcRole();

    const { openSnackbar } = useSnackbar();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            name: "",
            displayName: "",
            supervisorUuid: isBasicRole ? contract.supervisorUuid : "",
            countryCode: null,
        },
    });

    const countriesArray = useMemo(
        () =>
            iso31661.sort((countryA, countryB) =>
                countryA.name > countryB.name ? 1 : -1
            ),
        []
    );

    const { post } = useFetch();

    const onSubmit = async (payload) => {
        const usedDisplayName =
            payload?.displayName !== "" ? payload.displayName : undefined;
        const createdSite = await post(
            `${BACKEND_ROUTES.CONTRACT}/${contract.uuid}/sites`,
            {
                body: {
                    ...payload,
                    countryCode: payload.countryCode?.alpha3 ?? null,
                    displayName: usedDisplayName,
                },
            }
        );

        if (createdSite) {
            mutate();
            closeModal();
            openSnackbar(
                `Site ${createdSite.displayName} created successfully.`,
                "success"
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create a new site</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Stack spacing={3}>
                    <TextField
                        id="name"
                        fullWidth
                        type="text"
                        label="Name *"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        {...register("name", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                    {!isBasicRole && (
                        <>
                            <TextField
                                id="displayName"
                                fullWidth
                                type="text"
                                label="Display name"
                                error={Boolean(errors.displayName)}
                                helperText={errors.displayName?.message}
                                {...register("displayName")}
                            />
                            <Controller
                                control={control}
                                name="supervisorUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="supervisorUuid"
                                        select
                                        label="Supervisor *"
                                        inputRef={ref}
                                        error={Boolean(errors.supervisorUuid)}
                                        helperText={
                                            errors.supervisorUuid?.message
                                        }
                                    >
                                        {users.map((user) => (
                                            <MenuItem
                                                key={user.uuid}
                                                value={user.uuid}
                                            >
                                                {`${user.firstName} ${user.lastName}`}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </>
                    )}
                    <Controller
                        control={control}
                        name="countryCode"
                        render={({
                            field: { ref, onChange, ...fieldProps },
                        }) => (
                            <Autocomplete
                                {...fieldProps}
                                id="countryCode"
                                options={countriesArray}
                                isOptionEqualToValue={(option, value) =>
                                    option.alpha3 === value.alpha3
                                }
                                fullWidth
                                onChange={(_, value) => {
                                    onChange(value);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box {...props} component="li">
                                        <Stack direction="row" spacing={1}>
                                            <CountryFlag country={option} />
                                            <span>{option.name}</span>
                                        </Stack>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={ref}
                                        label="Country"
                                    />
                                )}
                            />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={closeModal}>
                    Cancel
                </Button>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
