import { Controller, useWatch } from "react-hook-form";
import { Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";

import PropTypes from "prop-types";

PipelineTemplatePresetForm.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.any.isRequired,
    presetsData: PropTypes.object.isRequired,
};

export function PipelineTemplatePresetForm({ control, errors, presetsData }) {
    const presetWatch = useWatch({
        name: "presetUuid",
        control,
    });

    const filteredTraitGroupDataTypes = {};

    if (presetWatch)
        presetsData.rows
            .find((preset) => preset.uuid === presetWatch)
            .TraitGroupDataTypes.forEach((tgdt) => {
                if (!filteredTraitGroupDataTypes[tgdt.dataType])
                    filteredTraitGroupDataTypes[tgdt.dataType] = [];
                filteredTraitGroupDataTypes[tgdt.dataType].push(tgdt);
            });

    return (
        <Grid item xs={12} container spacing={1} alignContent="center">
            <Grid item xs={12}>
                <Typography>Select a preset</Typography>
                <Divider />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="presetUuid"
                    render={({ field: { ref, onChange, ...fieldProps } }) => (
                        <TextField
                            {...fieldProps}
                            fullWidth
                            id="presetUuid"
                            select
                            label="Preset"
                            inputRef={ref}
                            error={Boolean(errors.presetUuid)}
                            helperText={errors.presetUuid?.message}
                            onChange={(event) => {
                                // Need to set dependent fields to blank to avoid inconsistency
                                onChange(event.target.value);
                            }}
                        >
                            {presetsData.rows.map((preset) => (
                                <MenuItem key={preset.uuid} value={preset.uuid}>
                                    {preset.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
                {Object.keys(filteredTraitGroupDataTypes).map((dataType) => {
                    return (
                        <Grid key={dataType} item container spacing={0.5}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontStyle: "italic",
                                        color: "grey",
                                    }}
                                >
                                    <strong>{`Selected traits (${dataType})`}</strong>
                                </Typography>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} key={dataType}>
                                <Typography>
                                    {filteredTraitGroupDataTypes[dataType].map(
                                        (TGDT, index) =>
                                            `${index !== 0 ? " ; " : ""}${TGDT.TraitGroup.name}`
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
}
