import {
    Box,
    Card,
    Dialog,
    Icon,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useMemo, useState } from "react";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { CLOVERFIELD_MAP } from "../../constants";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { Link } from "react-router-dom";
import MapIcon from "@mui/icons-material/Map";
import { SiteCreateForm } from ".";
import { SiteTableSkeleton } from "./SiteTableSkeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { iso31661 as countriesArray } from "iso-3166";
import { getDataGridHeight } from "../utils/getDataGridHeight";
import { laggy } from "../../utils/SWRLaggy";
import { useKcRole } from "../../hooks/useKcRole";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const pageItemCount = 100;

export function SiteTable({ contract, hasLiteralTemplates }) {
    const { isBasicRole } = useKcRole();

    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(0);
    const {
        data: sites,
        error: sitesFetchError,
        mutate,
        isLagging,
    } = useSWR(
        `${
            BACKEND_ROUTES.SITE
        }?parentInfo=true&contractUuid=${contract.uuid}&limit=${pageItemCount}&offset=${
            page * pageItemCount
        }`,
        { use: [laggy] }
    );

    const { data: users, error: usersFetchError } = useSWRImmutable(
        isBasicRole ? null : `${BACKEND_ROUTES.USER}?sort=firstName`
    );

    const gridRows = useMemo(
        () =>
            sites
                ? sites.rows.map((site) => {
                      return {
                          id: site.uuid,
                          uuid: site.uuid,
                          name: site.name,
                          displayName: site.displayName,
                          supervisor: `${site.Supervisor.firstName} ${site.Supervisor.lastName}`,
                          supervisorUuid: site.supervisorUuid,
                          country: site.countryCode
                              ? countriesArray.find(
                                    (country) =>
                                        country.alpha3 === site.countryCode
                                )?.name ?? "Unknown country"
                              : "No country",
                          cloverfieldUuid: site.cloverfieldUuid,
                      };
                  })
                : [],
        [sites]
    );

    const mergedFetchError = usersFetchError ?? sitesFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!sites || (!users && !isBasicRole)) return <SiteTableSkeleton />;

    const closeModal = () => {
        setIsOpen(false);
    };

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            minWidth: 120,
            flex: 0.5,
            getActions: (params) => {
                const returnedArray = [
                    <Tooltip title="View details">
                        <GridActionsCellItem
                            label="View details"
                            icon={
                                <VisibilityIcon
                                    fontSize="small"
                                    color="primary"
                                />
                            }
                            component={Link}
                            to={`/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}/${contract.uuid}/${FRONTEND_ROUTES.SITE}/${params.row.id}`}
                        />
                    </Tooltip>,
                ];

                if (hasLiteralTemplates)
                    returnedArray.push(
                        <Tooltip title="Literal Plot Map Tool">
                            <GridActionsCellItem
                                label="Literal Plot Map Tool"
                                icon={
                                    <MapIcon fontSize="small" color="primary" />
                                }
                                component={Link}
                                to={`/${FRONTEND_ROUTES.PARCELLAIRE_LITERAL}/${params.row.id}`}
                            />
                        </Tooltip>
                    );

                const cloverfieldLinkDisabled =
                    !params.row.cloverfieldUuid || !CLOVERFIELD_MAP;
                returnedArray.push(
                    <Tooltip title="Go to Cloverfield site">
                        <GridActionsCellItem
                            label="Go to Cloverfield site"
                            icon={
                                <Icon
                                    fontSize="small"
                                    sx={{
                                        content: !cloverfieldLinkDisabled
                                            ? "url('/static/cloverfield_logo_green.png')"
                                            : "url('/static/cloverfield_logo_grey.png')",
                                    }}
                                />
                            }
                            component={Link}
                            disabled={cloverfieldLinkDisabled}
                            to={`${CLOVERFIELD_MAP}/${params.row.cloverfieldUuid}`}
                        />
                    </Tooltip>
                );

                return returnedArray;
            },
            filterable: false,
        },
        {
            field: "displayName",
            headerName: "Name",
            minWidth: 250,
            flex: 3,
        },
        ...(isBasicRole
            ? []
            : [
                  {
                      field: "name",
                      headerName: "Technical name",
                      minWidth: 250,
                      flex: 3,
                  },
              ]),
        {
            field: "country",
            headerName: "Country",
            minWidth: 250,
            flex: 3,
        },
        ...(isBasicRole
            ? []
            : [
                  {
                      field: "supervisor",
                      headerName: "Supervisor",
                      minWidth: 250,
                      flex: 2,
                  },
              ]),
    ];

    return (
        <Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" gutterBottom>
                    Sites
                </Typography>
                <Stack direction="row" spacing={1}>
                    <IconButton size="large" onClick={() => setIsOpen(true)}>
                        <AddCircleOutlinedIcon
                            fontSize="inherit"
                            color="primary"
                        />
                    </IconButton>
                    <Dialog open={isOpen} fullWidth maxWidth="sm">
                        <SiteCreateForm
                            contract={contract}
                            closeModal={closeModal}
                            mutate={mutate}
                            users={
                                users ??
                                (contract.Supervisor
                                    ? [contract.Supervisor]
                                    : [])
                            }
                        />
                    </Dialog>
                </Stack>
            </Stack>

            <Card>
                <Box
                    sx={{
                        height: getDataGridHeight(400, sites.rows.length),
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={gridRows}
                        columns={gridColDef}
                        pageSizeOptions={[pageItemCount]}
                        rowCount={sites.count}
                        paginationMode="server"
                        paginationModel={{ page, pageSize: pageItemCount }}
                        onPaginationModelChange={(newPageModel) => {
                            setPage(newPageModel.page);
                        }}
                        loading={isLagging}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "name", sort: "asc" }],
                            },
                        }}
                    />
                </Box>
            </Card>
        </Stack>
    );
}
