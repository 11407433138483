import {
    Autocomplete,
    Box,
    Button,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CountryFlag } from "../../components/CountryFlag";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { INPUT_VALIDATION } from "../../constants";
import { LoadingButton } from "@mui/lab";
import { SiteEditFormSkeleton } from "./SiteEditFormSkeleton";
import { iso31661 } from "iso-3166";
import { styled } from "@mui/material/styles";
import { useKcRole } from "../../hooks/useKcRole";
import useSWR from "swr";

const LabelStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: 600,
}));

export const SiteEditForm = ({ siteUuid }) => {
    const { isBasicRole } = useKcRole();

    const { openSnackbar } = useSnackbar();

    const {
        data: site,
        error: siteFetchError,
        mutate,
    } = useSWR(`${BACKEND_ROUTES.SITE}/${siteUuid}?parentInfo=true`);

    const { data: users, error: usersFetchError } = useSWR(
        isBasicRole ? null : `${BACKEND_ROUTES.USER}?sort=firstName`
    );

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isDirty },
    } = useForm({
        defaultValues: {
            name: "",
            displayName: "",
            contractUuid: "",
            supervisorUuid: "",
            countryCode: null,
        },
    });

    const countriesArray = useMemo(
        () =>
            iso31661.sort((countryA, countryB) =>
                countryA.name > countryB.name ? 1 : -1
            ),
        []
    );

    // this useEffect makes it so the form is reset after mutate, which updates the default values and the isDirty flag
    useEffect(() => {
        if (site) {
            reset({
                name: site.name,
                displayName: site.displayName,
                contractUuid: site.contractUuid,
                supervisorUuid: site.supervisorUuid,
                countryCode: site.countryCode
                    ? countriesArray.find(
                          (country) => country.alpha3 === site.countryCode
                      ) ?? null
                    : null,
            });
        }
    }, [site, reset, countriesArray]);

    const { patch } = useFetch();

    const mergedFetchError = siteFetchError ?? usersFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (!site || (!users && !isBasicRole)) return <SiteEditFormSkeleton />;

    const onSubmit = async (payload) => {
        const updatedSite = await patch(`${BACKEND_ROUTES.SITE}/${site.uuid}`, {
            body: {
                ...payload,
                countryCode: payload.countryCode?.alpha3 ?? null,
            },
        });

        if (updatedSite) {
            openSnackbar(
                `Site ${updatedSite.displayName} updated successfully.`,
                "success"
            );

            mutate();
        }
    };

    const onCancel = () => {
        reset();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Site management
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>Campaign</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{site.Contract.name}</Typography>
                </Grid>
                <Grid item xs={6} />
                {!isBasicRole && (
                    <>
                        <Grid item xs={2}>
                            <LabelStyle>Name</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="name"
                                id="name"
                                fullWidth
                                type="text"
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                                {...register("name", {
                                    required: INPUT_VALIDATION.REQUIRED,
                                })}
                            />
                        </Grid>
                        <Grid item xs={6} />
                    </>
                )}
                <Grid item xs={2}>
                    <LabelStyle>
                        {isBasicRole ? "Name" : "Display Name"}
                    </LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="displayName"
                        id="displayName"
                        disabled={isBasicRole}
                        fullWidth
                        type="text"
                        error={Boolean(errors.displayName)}
                        helperText={errors.displayName?.message}
                        {...register("displayName", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                </Grid>
                <Grid item xs={6} />
                {!isBasicRole && (
                    <>
                        <Grid item xs={2}>
                            <LabelStyle>Site Supervisor</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="supervisorUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="supervisorUuid"
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.supervisorUuid)}
                                        helperText={
                                            errors.supervisorUuid?.message
                                        }
                                    >
                                        {users ? (
                                            users.map((user) => (
                                                <MenuItem
                                                    key={user.uuid}
                                                    value={user.uuid}
                                                >
                                                    {user.firstName}{" "}
                                                    {user.lastName}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem
                                                key={site.supervisorUuid}
                                                value={site.supervisorUuid}
                                            >
                                                {site.Supervisor.firstName}{" "}
                                                {site.Supervisor.lastName}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} />
                    </>
                )}
                <Grid item xs={2}>
                    <LabelStyle>Country</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        control={control}
                        name="countryCode"
                        render={({
                            field: { ref, onChange, ...fieldProps },
                        }) => (
                            <Autocomplete
                                {...fieldProps}
                                id="countryCode"
                                disabled={isBasicRole}
                                options={countriesArray}
                                isOptionEqualToValue={(option, value) =>
                                    option.alpha3 === value.alpha3
                                }
                                fullWidth
                                onChange={(_, value) => {
                                    onChange(value);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box {...props} component="li">
                                        <Stack direction="row" spacing={1}>
                                            <CountryFlag country={option} />
                                            <span>{option.name}</span>
                                        </Stack>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={ref}
                                        label="Country"
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} />

                {isDirty && (
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            spacing={3}
                            justifyContent="flex-end"
                        >
                            <Button type="button" onClick={onCancel}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Update
                            </LoadingButton>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};
